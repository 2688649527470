.guest-service-modal .guest-service-modal-body {
  padding: var(--spacing-md) var(--spacing-lg) var(--spacing-md) var(--spacing-s);
}
.guest-service-modal .guest-service-modal-body .guest-service-modal-description {
  padding: 0 var(--spacing-md);
}
.guest-service-modal .guest-service-modal-body .guest-service-modal-list {
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0;
  padding: 0;
}
.guest-service-modal .guest-service-modal-body .guest-service-modal-list .guest-service-modal-list-item {
  cursor: pointer;
  min-height: 46px;
}
