.occupancy-discrepancy .occupancy-discrepancy-error {
  color: var(--color-alert);
}
.occupancy-discrepancy .ac-button .occupancy-discrepancy-error .ac-icon {
  color: var(--color-alert);
}
.occupancy-discrepancy .occupancy-discrepancy-remove-area {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 30px;
}
